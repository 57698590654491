<template>
    <main class="main-page">
    <template v-if="showHeader">
        <section class="page-section mb-3" >
            <div class="container">
                <div class="grid align-items-center">
                    <div  v-if="!isSubPage"  class="col-fixed " >
                        <Button @click="$router.go(-1)" label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                    </div>
                    <div  class="col " >
                        <div class="" >
                            <div class="flex align-items-center ">
                                <div class="mr-3">
                                    <Avatar size="large" class="" icon="pi pi-pencil" />
                                    </div>
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Edit Room</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div  class="md:col-9 sm:col-12 comp-grid" >
                        <div >
                            <template v-if="pageReady">
                                <form ref="observer"  tag="form" @submit.prevent="submitForm()" :class="{ 'card': !isSubPage }" class="">
                                    <!--[form-content-start]-->
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="mb-2 font-bold">Room Type *</div>
                                            <div id="ctrl-room_type-holder"> 
                                                <InputText ref="ctrlroom_type" v-model.trim="formData.room_type"  label="Room Type" type="text" placeholder="Enter Room Type"      
                                                class=" w-full" :class="getErrorClass('room_type')">
                                                </InputText>
                                                <small v-if="isFieldValid('room_type')" class="p-error">{{ getFieldError('room_type') }}</small> 
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="mb-2 font-bold">  Booking Fee *</div>
                                            <div id="ctrl-booking_fee-holder"> 
                                                <InputText ref="ctrlbooking_fee" v-model.trim="formData.booking_fee"  label="Booking Fee" type="number" placeholder="Enter Booking Fee"   min="0" max="5000" step="1"    
                                                class=" w-full" :class="getErrorClass('booking_fee')">
                                                </InputText>
                                                <small v-if="isFieldValid('booking_fee')" class="p-error">{{ getFieldError('booking_fee') }}</small> 
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="mb-2 font-bold"> Price *</div>
                                            <div id="ctrl-price-holder"> 
                                                <InputText ref="ctrlprice" v-model.trim="formData.price"  label="Price" type="number" placeholder="Enter Price"   step="0.1"    
                                                class=" w-full" :class="getErrorClass('price')">
                                                </InputText>
                                                <small v-if="isFieldValid('price')" class="p-error">{{ getFieldError('price') }}</small> 
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="mb-2 font-bold">  Available Slots </div>
                                            <div id="ctrl-available_slots-holder"> 
                                                <InputText ref="ctrlavailable_slots" v-model.trim="formData.available_slots"  label="Available Slots" type="number" placeholder="Enter Available Slots"   min="0" max="9999" step="1"    
                                                class=" w-full" :class="getErrorClass('available_slots')">
                                                </InputText>
                                                <small v-if="isFieldValid('available_slots')" class="p-error">{{ getFieldError('available_slots') }}</small> 
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-6">
                                            <div class="mb-2 font-bold"> Booked Slots </div>
                                            <div id="ctrl-booked_slots-holder"> 
                                                <InputText ref="ctrlbooked_slots" v-model.trim="formData.booked_slots"  label="Booked Slots" type="number" placeholder="Enter Booked Slots"   min="0" max="9999" step="1"    
                                                class=" w-full" :class="getErrorClass('booked_slots')">
                                                </InputText>
                                                <small v-if="isFieldValid('booked_slots')" class="p-error">{{ getFieldError('booked_slots') }}</small> 
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="mb-2 font-bold">Images *</div>
                                            <div id="ctrl-images-holder"> 
                                                <div class="mb-3">
                                                    <Uploader :class="getErrorClass('images')" :auto="true" :fileLimit="10" :maxFileSize="5000000" accept=".jpg,.png,.gif,.jpeg" :multiple="true" style="width:100%" label="Choose files or drop files here" upload-path="fileuploader/upload/images" v-model="formData.images"></Uploader>
                                                </div>
                                                <small v-if="isFieldValid('images')" class="p-error">{{ getFieldError('images') }}</small> 
                                            </div>
                                        </div>
                                    </div>
                                    <!--[form-content-end]-->
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </template>
                            <template v-if="loading">
                                <div class="p-3 text-center">
                                    <ProgressSpinner style="width:50px;height:50px" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, minValue, maxValue, numeric, } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'rooms',
		},
		routeName: {
			type: String,
			default: 'roomsedit',
		},
		pagePath: {
			type : String,
			default : 'rooms/edit',
		},
		apiPath: {
			type: String,
			default: 'rooms/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: 'Update Record',
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		msgAfterSave: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	const formDefaultValues = Object.assign({
		room_type: "", 
		booking_fee: "0.00", 
		price: "0.00", 
		available_slots: "0", 
		booked_slots: "0", 
		images: [], 
	}, props.pageData);
	const formData = reactive({ ...formDefaultValues });
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigateTo(`/rooms`);
		}
	}
	const rules = computed(() => {
		return {
			room_type: { required },
			booking_fee: { required, numeric, minValue: minValue(0), maxValue: maxValue(5000) },
			price: { required, numeric },
			available_slots: { numeric, minValue: minValue(0), maxValue: maxValue(9999) },
			booked_slots: { numeric, minValue: minValue(0), maxValue: maxValue(9999) },
			images: { required }
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage({ props, formData, v$, afterSubmit });
	const { submitted, saving, loading, pageReady } = toRefs(page.state);
	const { apiUrl, currentRecord } = page.computedProps;
	const { load, submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
	onMounted(()=>{
		const pageTitle = "Edit Room";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
